import React, { forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.js";
import { Card } from "clutch/src/Card/Card.js";

import { formatDuration } from "@/app/util.mjs";
import {
  Container,
  SwarmRoster,
} from "@/game-lol/components/SwarmScore.style.jsx";
import { CHAMPION_ID_MAP } from "@/game-lol/constants/swarm-constants.mjs";
import { separateDerivedRiotID } from "@/game-lol/utils/derived-id.mjs";
import Static from "@/game-lol/utils/static.mjs";
import {
  getChampionKeyById,
  getStaticData,
  isSwarmQueue,
} from "@/game-lol/utils/util.mjs";
import Gold from "@/inline-assets/hextech-match-gold.svg";
import XP from "@/inline-assets/hextech-stat-experience.svg";
import Sword from "@/inline-assets/hextech-stats-melee.svg";
import Minion from "@/inline-assets/lol-minion.svg";
import Share from "@/inline-assets/share.svg";
import WordMark from "@/inline-assets/wordmark-with-bolt.svg";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";

type SwarmParticipant = {
  participantId: number;
  isLocalPlayer: boolean;
  championId: number;
  riotIdGameName: string;
  riotIdTagline: string;
  summonerName: string;
  item0: number;
  item1: number;
  item2: number;
  item3: number;
  item4: number;
  playerAugment1: number;
  playerAugment2: number;
  playerAugment3: number;
  playerAugment4: number;
  playerAugment5: number;
  playerAugment6: number;
  win: boolean;
  champLevel: number;
  goldEarned: number;
  timePlayed: number;
  totalMinionsKilled: number;
  totalDamageDealt: number;
};
type SwarmScoreProps = {
  name: string;
  sharable: boolean;
  currParticipant: unknown;
  matchData: {
    queueId: number | symbol;
    gameMode: string;
    participants: SwarmParticipant[];
  };
  timelineData: {
    frames: { participantFrames: [] }[];
  };
  className?: string;
};

function SwarmScore({
  name,
  sharable,
  matchData,
  timelineData,
  className,
}: SwarmScoreProps) {
  const { currentPath } = useRoute();
  const { t } = useTranslation();
  const localPlayerCard = useRef(null);
  const [copied, setCopied] = useState(false);
  const [showShare, setShowShare] = useState(true);

  const [gameName, tagLine] = separateDerivedRiotID(name);

  const isSwarm = isSwarmQueue(matchData?.queueId || matchData.gameMode);

  function copyLink() {
    if (!matchData) return;
    const link = `${globals.location.origin}${encodeURI(currentPath)}`;
    globals.navigator.clipboard.writeText(link);
    setCopied(true);
    sendInteractionEvent("swarm-score-copied");
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }

  if (!matchData || !gameName || !isSwarm) return null;

  // TODO: revist using timeline data
  const timelineFrames = (timelineData?.frames || []).reduce((acc, frame) => {
    const { participantFrames } = frame;

    for (const participantId in participantFrames) {
      const participantFrame = participantFrames[participantId] as {
        currentGold: number;
        minionsKilled: number;
        level: number;
        damageStats: { totalDamageDone: number };
      };

      if (!acc[participantId]) acc[participantId] = [];
      acc[participantId].push({
        damage: participantFrame.damageStats.totalDamageDone,
        gold: participantFrame.currentGold,
        minionsKilled: participantFrame.minionsKilled,
        level: participantFrame.level,
      });
    }

    return acc;
  }, {});

  const swarmPlayers = (matchData?.participants || [])
    .map((p) => {
      const name = p.riotIdGameName || p.summonerName;

      const stats = timelineFrames[p.participantId];

      const items = [p.item0, p.item1, p.item2, p.item3, p.item4];
      const augments = [
        p.playerAugment1,
        p.playerAugment2,
        p.playerAugment3,
        p.playerAugment4,
        p.playerAugment5,
        p.playerAugment6,
      ];

      const championId = CHAMPION_ID_MAP[p.championId];
      const championKey = getChampionKeyById(championId);

      return {
        sort:
          p.isLocalPlayer ||
          (gameName === p.riotIdGameName && tagLine === p.riotIdTagline)
            ? 999
            : 0,
        name,
        championId,
        championKey,
        goldEarned: p.goldEarned,
        items,
        augments,
        win: p.win,
        level: p.champLevel,
        gold: p.goldEarned,
        timePlayed: p.timePlayed,
        liveclient: stats,
        creepScore: p.totalMinionsKilled,
        damage: p.totalDamageDealt,
        stats,
      };
    })
    .sort((a, b) => b.sort - a.sort)
    .filter((p) => p.championId);

  return (
    <div {...classNames(className, SwarmRoster())}>
      <div>
        <SwarmLocalPlayer player={swarmPlayers[0]} ref={localPlayerCard} />
        {sharable && (
          <div className="share">
            <Button
              size="large"
              iconLeft={<Share />}
              bgColor={copied ? "var(--lime)" : "var(--shade0)"}
              bgColorHover={copied ? "var(--lime)" : "var(--shade0)"}
              textColor="var(--shade10)"
              textColorHover="var(--shade10)"
              onClick={() => {
                copyLink();
              }}
              className="share-btn"
              style={{ opacity: showShare ? 1 : 0 }}
            >
              {copied
                ? t("common:share.copied", "Web link copied to clipboard!")
                : t("lol:swarm.shareSwarmRun", "Share Swarm Run")}
            </Button>
            <button
              className="hide-show type-subtitle"
              onClick={() => setShowShare(!showShare)}
            >
              {showShare
                ? t("common:hideShareButton", "Hide share button")
                : t("common:showShareButton", "Show share button")}
            </button>
          </div>
        )}
      </div>
      <div className="teammates">
        <SwarmTeammate player={swarmPlayers[1]} />
        <SwarmTeammate player={swarmPlayers[2]} />
        <SwarmTeammate player={swarmPlayers[3]} />
      </div>
    </div>
  );
}

export default SwarmScore;

const STATS = [
  {
    key: "damage",
    label: ["common:stats.damageDealt", "Damage Dealt"] as Translation,
    color: "var(--purple)",
  },
  {
    key: "minionsKilled",
    label: ["lol:stat.minionsSlain", "Minions Slain"] as Translation,
    color: "var(--lime)",
  },
  {
    key: "level",
    label: ["common:level", "Level"] as Translation,
    color: "var(--blue)",
  },
  {
    key: "gold",
    label: [
      "lol:postmatch.advancedStatsSubHeaders.goldEarned",
      "Gold Earned",
    ] as Translation,
    color: "var(--gold)",
  },
];

function LocalPlayer({ player }, ref) {
  const { t } = useTranslation();
  const [selectedStat, setSelectedStat] = useState(STATS[0].key);

  if (!player) return null;

  const renderedLiveclientStat = (player.liveclient || []).map(
    (s) => s[selectedStat],
  );

  const champions = getStaticData("champions");
  const championInfo =
    player.championId && champions?.[champions?.keys?.[player.championId]];

  return (
    <Card
      className={Container()}
      style={{
        "--stat-color": STATS.find((s) => s.key === selectedStat)?.color,
        "--outcome-color": player.win ? "var(--turq)" : "var(--red)",
      }}
      data-win={!!player.win}
      ref={ref}
    >
      <img
        src={Static.getChampionSwarmImage(player.championId, true)}
        className="splash-bg"
        width="1280"
        height="720"
      />
      <WordMark className="wordmark" />
      <div className="title">
        <h3 className="outcome">
          {player.win
            ? t("common:stats.victory", "Victory")
            : t("common:stats.defeat", "Defeat")}
        </h3>
        <span className="duration type-page-header">
          {formatDuration(player.timePlayed * 1000, "mm:ss")}
        </span>
      </div>
      <div className="middle">
        <ul className="stats">
          <li className="stat type-subtitle">
            <Minion width="80" height="80" />
            <span className="val">
              {player.creepScore.toLocaleString(getLocale())}
            </span>
            <span className="label type-subtitle--semi">
              {t("lol:stat.minionsSlain", "Minions Slain")}
            </span>
          </li>
          <li className="stat type-subtitle">
            <Gold width="80" height="80" />
            <span className="val">
              {player.gold.toLocaleString(getLocale())}
            </span>
            <span className="label type-subtitle--semi">
              {t("common:stats.goldCollected", "Gold Collected")}
            </span>
          </li>
          <li className="stat type-subtitle">
            <Sword width="80" height="80" />
            <span className="val">
              {player.damage.toLocaleString(getLocale())}
            </span>
            <span className="label type-subtitle--semi">
              {t("common:stats.damageDealt", "Damage Dealt")}
            </span>
          </li>
          <li className="stat type-subtitle">
            <XP width="80" height="80" />
            <span className="val">
              {player.level.toLocaleString(getLocale())}
            </span>
            <span className="label type-subtitle--semi">
              {t("common:level", "Level")}
            </span>
          </li>
        </ul>
        <div className="meta">
          {championInfo?.name && (
            <h4 className="champion-name">{championInfo.name}</h4>
          )}
          {player.name && <span className="player-name">{player.name}</span>}
          {!!player.items?.length && (
            <ul className="items">
              {player.items.map((itemId, i) => {
                return (
                  <li key={i} className="item">
                    <img
                      src={Static.getSwarmItemImage(itemId)}
                      width="48"
                      height="48"
                    />
                  </li>
                );
              })}
            </ul>
          )}
          {!!player.augments?.length && (
            <ul className="items">
              {player.augments.map((augmentId, i) => {
                return (
                  <li key={i} className="item">
                    <img
                      src={Static.getSwarmAugmentImage(augmentId)}
                      width="34"
                      height="34"
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      {!!renderedLiveclientStat?.length &&
        renderedLiveclientStat.length >= 5 && (
          <>
            <hr />
            <div>
              <div className="bars-btns">
                {STATS.map((stat) => {
                  return (
                    <button
                      key={stat.key}
                      onClick={() => setSelectedStat(stat.key)}
                      {...classNames(
                        "type-caption--semi",
                        stat.key === selectedStat && "selected",
                      )}
                    >
                      {t(...stat.label)}
                    </button>
                  );
                })}
              </div>
              <div className="bars">
                {renderedLiveclientStat.map((stat, i) => {
                  const percent = stat / renderedLiveclientStat.at(-1);
                  return (
                    <div
                      key={i}
                      className="bar"
                      style={{ "--fill": percent < 0.05 ? 0.05 : percent }}
                    >
                      <span className="type-caption--bold">
                        {stat.toLocaleString(getLocale())}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      <span className="outcome-overlay">
        {player.win
          ? t("common:stats.victory", "Victory")
          : t("common:stats.defeat", "Defeat")}
      </span>
    </Card>
  );
}
const SwarmLocalPlayer = forwardRef(LocalPlayer);

function SwarmTeammate({ player }) {
  const { t } = useTranslation();

  if (!player) return null;

  const champions = getStaticData("champions");
  const championInfo =
    player.championId && champions?.[champions?.keys?.[player.championId]];

  return (
    <Card {...classNames(Container(), "is-teammate")}>
      <img
        src={Static.getChampionSwarmImage(player.championId, true)}
        className="splash-bg"
        width="1280"
        height="720"
      />
      <ul className="stats">
        <li className="stat type-subtitle">
          <Minion width="80" height="80" />
          <span className="val">
            {player.creepScore.toLocaleString(getLocale())}
          </span>
          <span className="label type-subtitle--semi">
            {t("lol:stat.minionsSlain", "Minions Slain")}
          </span>
        </li>
        <li className="stat type-subtitle">
          <Gold width="80" height="80" />
          <span className="val">{player.gold.toLocaleString(getLocale())}</span>
          <span className="label type-subtitle--semi">
            {t("common:stats.goldCollected", "Gold Collected")}
          </span>
        </li>
        <li className="stat type-subtitle">
          <Sword width="80" height="80" />
          <span className="val">
            {player.damage.toLocaleString(getLocale())}
          </span>
          <span className="label type-subtitle--semi">
            {t("common:stats.damageDealt", "Damage Dealt")}
          </span>
        </li>
        <li className="stat type-subtitle">
          <XP width="80" height="80" />
          <span className="val">
            {player.level.toLocaleString(getLocale())}
          </span>
          <span className="label type-subtitle--semi">
            {t("common:level", "Level")}
          </span>
        </li>
      </ul>
      {championInfo && (
        <div className="meta">
          <h4 className="champion-name">{championInfo.name}</h4>
          <span className="player-name">{player?.name}</span>
        </div>
      )}
    </Card>
  );
}
